import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const RichlinkBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      showHeadline="false"
      showButtons="false"
      className=""
    >
      <Link className="block border group" link={block.link}>
        <div className="flex flex-row flex-wrap">
          <div className="w-full md:w-1/2 lg:w-2/3 hover:bg-gray-50">
            <div className="p-8">
              {block.title?.length > 0 && (
                <span className="block mb-4 text-xl text-primary">
                  {block.title}
                </span>
              )}
              {block.description?.length > 0 && (
                <span className="block">{block.description}</span>
              )}
              {block.link_text.length > 0 && (
                <span className="block underline text-primary group-hover:text-primary-hover">
                  {block.link_text}
                </span>
              )}
            </div>
          </div>
          <div className="relative order-first w-full md:order-last md:w-1/2 lg:w-1/3">
            {block.image && (
              <div className="inset-0 overflow-hidden md:absolute ">
                <Image
                  className="object-cover object-center w-full h-full"
                  image={block.image}
                />
              </div>
            )}
          </div>
        </div>
      </Link>
    </BlockWrapper>
  )
}

export default RichlinkBlock
